<script>
import {UTable, UTh, UTr, UTd, UCheckbox, date} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import {ErpInput, /*ErpSelect,*/ ErpSField} from 'uloc-vue-plugin-erp'
import {datePtToEn} from '@/utils/date'
import DefaultMixin from '../../mixin'
import SearchFilter from "../../../layout/helpers/SearchFilter";
import TipoArrematanteSelect from "../../../arrematante/components/include/TipoArrematanteSelect";
import DateInput from "../../../../reuse/input/Date";
import MenuOptions from "../../../layout/context-menu/context-window-options";
import juizWindow from './window/windowJuiz'
import Menu from '../menu/menu'
import {list} from '../../../../domain/processos/services/juiz'
import UfSelect from "../../../remocao/components/include/UfSelect";
import MenuOptionsItem from "../../../layout/context-menu/context-window-options-item";
import resolver from './resolver'

let filters = {
  id: '',
  tipo: '',
  search: '',
  documento: '',
  data1: '',
  data2: '',
  uf: '',
}

export default {
  name: 'Juizes',
  mixins: [DefaultMixin],
  components: {
    MenuOptionsItem,
    UfSelect,
    MenuOptions,
    DateInput,
    TipoArrematanteSelect,
    SearchFilter,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSField
  },
  data () {
    return {
      filtersData: {
        uf: {
          loading: true,
          serverData: []
        }
      },
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: resolver.columns,
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    visibledColumns () {
      return ['id', 'nome', 'dataCadastro', 'documento', 'options']
    },
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    request ({pagination, filter}) {
      let data1 = ''
      let data2 = ''
      let extraFilters = []
      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        extraFilters.push(`&data1=${data1}`)
        extraFilters.push(`&data2=${data2}`)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
      }

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)
      this.table.filters.uf && extraFilters.push(`&uf=${this.table.filters.uf}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status}`)

      let documento = String(this.table.filters.documento).replace(/\D/gi, '') // TODO: Only numbers

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${this.table.filters.search}&documento=${documento}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result

            this.table.loading = false
          })
          .catch(error => {
            this.loading = false
          })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
      this.juizWindow()
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.search
      })
    },
    abrir (id) {
      console.log('Abrir window juiz')
      this.juizWindow(id)
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este arrematante? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir arrematante-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteArrematante(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Arrematante excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    juizWindow
  },
  meta: {
    title: 'Juízes',
    name: 'Juízes'
  }
}
</script>

<template>
    <div class="default-list">
      <div class="wrapper-md" style="padding-bottom: 0">
        <p class="font-16 font-bold">Juízes</p>
      </div>
      <search-filter>
        <form class="m-t-sm">
          <div class="row erp-row def-m-r">

            <div class="col" style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Código"
              >
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F3" v-model="table.filters.id" />
              </erp-s-field>
            </div>

            <div class="col" style="min-width: 126px; max-width: 126px">
              <tipo-arrematante-select select-null v-model="table.filters.tipo" />
            </div>

            <div class="col">
              <erp-s-field
                  view="tl"
                  label="Nome/Email/Telefone"
              >
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F2" v-model="table.filters.search" />
              </erp-s-field>
            </div>

            <div class="col">
              <erp-s-field
                  view="tl"
                  label="Documento"
              >
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F4" v-model="table.filters.documento" />
              </erp-s-field>
            </div>

            <div class="col" style="min-width: 146px; max-width: 146px">
              <date-input label="Data de cadastro (De)" v-model="table.filters.data1" />
            </div>

            <div class="col" style="min-width: 146px; max-width: 146px">
              <date-input label="Data de cadastro (Até)" v-model="table.filters.data2" />
            </div>

            <div class="col">
              <uf-select v-model="table.filters.uf" />
            </div>
          </div>

          <div class="row erp-row">
            <div class="col flex justify-end m-t-sm def-list-buttons" style="min-width: 300px">
              <div class="flex flex-center column m-r-xs">
                <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                       @click="pesquisar" />
                <span class="shortcut">Shift + Enter</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                       @shortkey.native="clearFilters" />
                <span class="shortcut">Ctrl + Shift + R</span>
              </div>
              <div class="flex flex-center column">
                <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']"
                       @shortkey.native="novo" />
                <span class="shortcut">Shift + N</span>
              </div>
            </div>
          </div>
        </form>
      </search-filter>
      <div class="erp-list">
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th :style="col.name === 'id' ? ' max-width: 25px' : ''" v-for="col in props.cols" :key="col.name"
                  :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>
            <u-td style="max-width: 25px" key="id" :props="props">
              {{props.row.id}}
            </u-td>
            <u-td key="nome"
                  :props="props">
              {{props.row.name}}
            </u-td>
            <u-td key="dataCadastro"
                  :props="props">
              {{props.row.createdAt.date|formatDate}}
            </u-td>
            <u-td key="documento" :props="props">
              <div v-if="Number(props.row.type) === 1">
                {{props.row.document|formataCpf}}
              </div>
              <div v-else>
                {{props.row.document|formataCnpj}}
              </div>
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <ul>
                    <menu-options-item close label="Abrir" @click="abrir(props.row)" icon="folder-open" />
                    <!--<menu-options-item close label="Excluir" @click="excluir(props.row)" icon="times"
                                       label-class="text-negative" />-->
                  </ul>
                </menu-options>
              </e-btn-table-options>
            </u-td>
          </u-tr>
        </u-table>
      </div>
    </div>
</template>

<style lang="stylus" src="../../../arrematante/assets/default.styl"></style>
