import {list} from '../../../../domain/processos/services/juiz'
import window from './window/windowJuiz'

export default {
  pageTitle: 'Juízes',
  columns: [
    {name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true},
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true
    },
    {
      name: 'dataCadastro',
      required: false,
      label: 'Data Cadastro',
      align: 'left',
      field: 'dataCadastro',
      sortable: true
    },
    {
      name: 'documento',
      required: false,
      label: 'Documento',
      align: 'left',
      field: 'documento',
      sortable: false
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: list,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this)
    }
  }
}
